import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { environment } from '@env/index';
import { AuthService } from '@app/core/features/auth';
import { Subject } from 'rxjs';
import { MsalBroadcastService } from '@azure/msal-angular';
import { filter, takeUntil } from 'rxjs/operators';
import { InteractionStatus } from '@azure/msal-browser';

// tslint:disable-next-line:ban-types
declare let gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy  {
  title = 'chart-manager';
  config = environment;
  isIframe = false;
  loginDisplay = false;
  private readonly destroying$ = new Subject<void>();

  isProcessing = false;

  constructor(
    private router: Router,
    private authService: AuthService,
    private msalBroadcastService: MsalBroadcastService
  ) {

    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        gtag('config', this.config.googleAnalyticsId, {
          page_path: event.urlAfterRedirects,
        });
      }
    });
  }

  ngOnInit(): void {
    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this.destroying$)
      )
      .subscribe(() => {
        console.log('MSAL in PROGRESS');
        this.authService.checkAndSetActiveAccount();
      });
  }

  ngOnDestroy(): void {
    this.destroying$.next(undefined);
    this.destroying$.complete();
  }
}
